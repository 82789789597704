import './App.scss';
import Landing from './pages/Landing';

function App() {
  window.document.body.classList.add('bg-dark')
  return (<>
    <Landing></Landing>
  </>
  );
}

export default App;
